<script lang="ts">

export interface IDataGridProps {
    dataObject?: DataObject,
    data?: Partial<{ index: number }>[],
    /** Columns passed as an object instead of slots */
    columns?: Record<string, any>[],
    /** String, dynamic class object or function that will be bound to the row class property. The current row is provided to the function as an argument */
    rowClass?: any,
    /** String, dynamic class object or function that will be bound to the row style property. The current row is provided to the function as an argument */
    rowStyle?: any,
    /** Title for the grid shown in the header. Must be provided if you want to use #cardheader slot. */
    headerTitle?: string,
    /**overwrite copy or paste functionality */
    overwriteCopy?: Function;
    overwritePaste?: Function;
    /**
    * The url to be used in the details iframe tab
    * @example `${site.oldGenUrl}/workflow-item?ID=${dsItems.current.ID}&HideNav=true`
    */
    detailIframe?: string,
    useDetailLoadingMessage?: boolean,
    /** Optional id to enable the message channel on the detail iframe  */
    detailMessageChannelId?: string,
    /** Map of functions callable by the iframe message channel */
    detailMessageChannelFunctions?: any,
    /** The label used on the detail iframe tab */
    detailTabTitle?: string,
    /** The label used on the detail iframe tab */
    noDetailHeader?: boolean,
    noDetailFooter?: boolean,
    /** The initial width on the sidepanel menu */
    initialMenuWidth?: string,
    /** Hide the status bar of this grid */
    noFooter?: boolean
    /** When set to true will not render header */
    noHeader?: boolean,
    /** When set to true will not render header row but will still render the header container */
    noHeaderRow?: boolean,
    /** Enables word wrapping for the header row */
    multilineHeader?: boolean,
    /** When set to true will not render the select all checkbox in the header */
    disableSelectAll?: boolean,
    /** When set to true will not render multi-select column */
    hideMultiselectColumn?: boolean,
    /** When set to true will not render action column */
    hideActionColumn?: boolean,
    /** When set to true will not render system column (current row indicator) */
    hideSystemColumn?: boolean,
    /** When set to true will stylize the active (current) row */
    activeRows?: boolean,
    noActiveRows?: boolean,
    /** Position where new records should be rendered. Currently running a trial with forcing only 'above-filters' */
    newRecordsPosition?: 'above-filters' | 'bottom',
    showNewRecordsPanel?: any;
    // newRecordsPosition?: 'top' | 'bottom' | 'above-filters',
    // TODO: Merge these together
    /** When set to true will disable the new record button and new records container */
    disableBatchRecords?: boolean,
    /** When set to true will render ImportData */
    importData?: boolean,
    /** When set to true will render ImportData */
    importDataBatch?: boolean,
    /**  Pass props in object form to o-365=data-import library */
    importDataProps?: object,
    /** When set to true will skip rendering of the new record rows */
    hideNewRecords?: boolean,
    /** When set to `true` will not render the grid sidepanel menu */
    hideGridMenu?: boolean,
    /** When set to `true` the grid setup menu will be initially collapsed */
    collapseGridMenu?: boolean,
    /** Optional max-width setting for the grid menu (in px or %) */
    gridMenuMaxWidth?: string
    /** Optional min-width setting for the grid menu (in px or %) */
    gridMenuMinWidth?: string
    /** When set to `true` will disable grid navigation features */
    disableNavigation?: boolean,
    /** When set to 'true' will disable sorting array data from grid */
    disableSorting?: boolean,
    /** Select list will contain only visible grid columns and sort order columns set on data object */
    onDemandFields?: boolean,
    /** When set to true the grid will load the dataobject after mount */
    loadDataObject?: boolean
    // /** Use delete confirm for delete actions. Is true by default */
    // disableDeleteConfirm?: boolean,
    /** Use soft delete for ActionDelete in grid */
    softDelete?: boolean,
    /** When set to true will not render filter row */
    disableFilterRow?: boolean,
    /** If provided will filter automatically when typing in filter cells with the debounce value. If provided value is false, then no automatic debouncing is performed. */
    autoFilterDebounce?: number | false,
    /** Use group by folders */
    groupByFolders?: boolean,
    /** An array of initial field filters. For example `['Title', {name:'StatusCode', distinct:'StatusCode'}]` */
    fieldFilters?: Array<string | { name: string, distinct: string }>,
    /**
    * An array of custom tab definitions for the grid sidemenu details tab
    * @example [
    *   { title: 'Custom Tab', id: 'tab1', iconClass: 'bi bi-1-square-fill', component: MyTabComponent}
    * ]
    */
    menuTabs?: Record<string, any>[],
    /** Enables grouping in the grid, can be passed as options object or as boolean for default configuration */
    groupBy?: object | boolean,
    /** Will disable the group by container, used when you don't want to allow the user to change group by settings */
    noGroupByContainer?: boolean,
    /** The column definition used when grouping is enabled for the Group column */
    groupColumnDefinition?: any,
    /** The column definition used to render TreeColumn when treeify is enabled on the provided dataobject */
    treeColumnDefinition?: any,
    /** Initial number of items to render for visual scroll. */
    itemsToRender?: number,
    /** Height used for all data rows in the grid. Must be a positive number */
    rowHeight?: number | string,
    /** Optional function for getting per row heights */
    getRowHeight?: (pRow: any) => number,
    disableDynamicLoading?: boolean,
    /**
    * Enables dynamic loading for the grid. When set to false will set the inner height to loaded data length.
    * When using Tree or GroupBy default is 'false' otherwise will be 'true'
    */
    dynamicLoading?: boolean,
    /** Override create new record function */
    createNewOverrideFn?: Function,
    /**
    * Override the row click handler, when provided will not set current index
    * @param {DataItemModel} row - the row that was clicked on
    * @param {MouseEvent} e - the click event 
    * @example (row, e) => dsTest.setCurrentIndex(row.index)
    */
    rowclickhandler?: Function,
    /** Returns grid control ref immediately after creation */
    eagerGridControl?: Function,
    systemPropertyIsEditable?: Function,
    /** @ignore */
    isLookup?: boolean,
    /**
    * GroupBy options
    * @ignore
    */
    groupByOptions?: object,
    /** @ignore */
    useLeftColumnSizers?: boolean,
    /** @ignore */
    menuTabContainerClass?: string,
    /** Optional object for overriding some grid features */
    gridApi?: {
        /** Override setCurrentIndex calls in the grid */
        setCurrentIndex?: (pIndex: number) => void;
        /** Override load function calls in the grid */
        load?: () => Promise<void>;
        /** Override save function calls in the grid */
        save?: (pIndex: number) => Promise<void>;
        /** Function for data objectless grids used to create new empty items */
        createNew?: () => Partial<DataItemModel>;
    },
    /**
     * Options for new and improved group by combined with tree structures. 
     * Very much still in progress, name and configuration will change.
     * @ignore
     */
    nodeData?: {
        /** 
         * Field used for displaying values in the group column for expanded rows,
         * can be changed per group in group by level configurations.
         */
        displayField: string;
        getDisplay?: (row: any) => string;
        /**
         * Indent for rows set with this formula: `${rowLevel * indent}px`
         * @default 24
         */
        indent?: number;
        /** Optional settings for the added group column definition */
        column?: {
            headerName?: string;
            headerTitle?: string;
            editable?: boolean;
            /** Default is on the display field, pass false to disable or custom filter component */
            filter?: any | boolean;
            /** @default 400 */
            width?: number;
            pinned?: 'left' | 'right';
            cellTitle?: string;
            boldDisplay?: boolean;
        };
    },
    disableColumnMove?: boolean;
    /** Options related to grid context menu */
    contextMenu?: {
        /**
         * Optional funcction to manipulate values used by 'Filter By Selection' and 
         * 'Filter By Excluding Selection'. Used when diffrent filter fields are used from the main field.
         */
        resolveFilterValues?: (pRow: Record<string, any>) => Record<string, any>;
    },
    /** Message object that will be posted to the detail iframe whenever it changes  */
    detailMessage?: any
    /** Api object for overriding various new records functionalities */
    newRecordsApi?: {
        focusFirstEditableCell?: (pGridControl: DataGridControl) => void;
    },
    /** When provided will enable support for persistent filters on the filter object */
    persistentFilterId?: string
    /** When true will always reload the summary row values on any data reload */
    alwaysReloadSummaryRow?: boolean;
    /**
     * When true will show 'No rows found...' message, can also be a string to override the default message.
     * If you need more custom markup you can set this to `true` and use noRowsFound slot.
     */
    noRowsFound?: boolean | string;
    /**
     * Adds 'Summary' tab under the column chooser allowing users to set custom aggregates on columns
     */
    userSummaryAggregates?: boolean;
    /** When set to true the flex styles on center columns container will be disabled, causing the right pinned columns to stick to the center columns  */
    disableRightPinnedOffset?: boolean;
    beforeContextMenuOpen?: (() => void);
    hideMenuItems?: string[];

    isFullWidthRow?: (pRow: any) => boolean;
    rowDragOptions?: { field?: string, step?: number, onAfterDrop?: Function, isRowDraggable?: (pRow: DataItemModel) => boolean };
    rowDrag?: boolean;
    renderCellBorders?: boolean;
    /** Auto collapse when viewport is smaller than this value */
    collapseMenuAt?: string;
    /** Show layout actions in the status bar */
    showLayoutsAtBottom?: boolean;
    /** Disable container (card) header */
    noContainerHeader?: boolean;
    /** Editable override from dataobject */
    editable?: boolean;
    /** When using data array select the first row when data becomes available  */
    selectFirstRowOnLoad?: boolean;
    /** Enable horizontal virtual scroll */
    enableVirtualColumns?: boolean;
    disableGridMenuPersist?: boolean;
    /** When true will exclude this grid from global click handler */
    disableSaveOnOutsideClicks?: boolean;
    autoScrollOnIndexChange?: boolean;
    /** Sync v-model:scrollTop value on the grid through a watcher */
    syncScrollTop?: boolean;
    /** When resizing the parent container (window, parent sizer, etc.) increase/decrease the size of the sidepanel instead of the grid */
    fixedGridSize?: boolean;
    /** Push menu tabs to vertical sidepanel tabs instead of the detail panel ( subject to change, used in special cases only for now)  */
    verticalTabs?: boolean;
};
</script>

<script setup lang="ts" >
/**
  * Data grid component
  * @definition
  */
import type { DataObject, DataItemModel } from 'o365-dataobject';

import DataGridControl from './DataGridControl.ts';

import DataColumns from './DataGrid.DataColumns.ts';

import OGridContextMenu from './components.ContextMenu.vue';
import ODataGridStatusBar from './components.StatusBar.vue';
import ODataGridHeader from './components.Header.vue';
import ODataGridDataList from './components.DataList.vue';
import { Overlay } from 'o365-ui-components';
import { parseColumnsFromVNodes } from './components.Columns.ts'; // Column config parser
import { InjectionKeys, $t } from 'o365-utils';
import { useDataObjectEventListener } from 'o365-vue-utils';
import { ref, computed, watch, onMounted, useSlots, useAttrs, reactive, toRef, nextTick, inject, resolveComponent, provide, onActivated, onDeactivated, onBeforeUnmount, isProxy, onUpdated } from 'vue';
import { BodyWrapper } from './helpers.jsx';
import useDataGridHover from './composables.Hover.ts';
import useArrayData from './composables.ArrayData.ts';
import useAllowEditWatcher from './composables.AllowEditWatcher.ts'
import ONewRecordsPanel from './components.NewRecordsPanel.vue';
import { useAsyncComponent, useErrorCapture, useVirtualScroll, OErrorBoundry as ErrorBoundry, useVirtualScroll2, useCancelToken } from 'o365-vue-utils';
import useVirtualColumns from './composables.ColumnVirtualization.ts';

import GridSidePanel from './components.SidePanel.vue';
import GridSidePanelTabs from './components.SidePanelTabs.vue';

import DataGridBodyRow from './components.BodyRow.vue';
import './extensions.Navigation.ts'; // Can't be imported in DataGrid.ts since its using it
import './extensions.LayoutManager.ts';
//-----------------------------------------------------------
const DataGridGroupByContainer = useAsyncComponent('o365-nodedata/groupbycontainer', {
    importFn: async () => {
        const nodedataModule = await import('o365-nodedata');
        return nodedataModule.OGroupByContainer;
    }
});
const ODataGridFooter = useAsyncComponent('o365-data-summary/GridFooter', {
    importFn: async () => {
        const summaryModule = await import('o365-data-summary');
        return summaryModule.ODataGridSummaryFooter;
    }
});

const props = withDefaults(defineProps<IDataGridProps>(), {
    hideNewRecords: raw => !!raw.isLookup,
    // showNewRecordsPanel: false,
    dynamicLoading: raw => !(raw.treeColumnDefinition || raw.groupBy || raw.nodeData || raw.disableDynamicLoading),
    // newRecordsPosition: 'above-filters',
    newRecordsPosition: 'bottom',
    activeRows: raw => !raw.noActiveRows,
    autoFilterDebounce: 500,
    rowHeight: 34,
    showLayoutsAtBottom: raw => !!raw.dataObject && !raw.isLookup,
    gridMenuMaxWidth: '98%',
    gridMenuMinWidth: raw => {
        if (raw.initialMenuWidth != null) {
            return raw.initialMenuWidth.includes('%') ? '10%' : '200px';
        } else {
            return '200px';
        }
    }
});


const emit = defineEmits<{
    (e: 'mounted'): void,
    (e: 'beforeCreate'): void,
    (e: 'beforePasteCommit', pOptions: {
        /** Items to be inserted */
        items: any[],
        /** Method that will be used to insert */
        type: 'oneByOne' | 'bulk'
        /** If type is `oneByOne` and this is set to true, then will only save one item at the time. */
        awaitOneByOne?: boolean
    }): void,
}>();


const slots = useSlots();
const attrs = useAttrs();

const masterGrid = inject(InjectionKeys.dataGridControlKey, null);

//--- DATA ---
const dataColumns = ref(null); // 🚩 Check
const scrollerWidth = ref(500);

const gridMenu = ref(null); // 🚩 Check

const showWidthScrollbar = ref(false);

// Refactor to not use ref
if (props.columns) {
    dataColumns.value = new DataColumns(props.columns, props.dataObject, {
        initialColumnsOptions: {
            hideMultiSelectColumn: props.hideMultiselectColumn,
            hideActionColumn: props.hideActionColumn,
            hideSystemColumn: props.hideSystemColumn,
        }
    });
} else {
    if (slots.default) {
        // Parse columns from slot
        const vnodes = slots.default();
        const parsedColumns = parseColumnsFromVNodes(vnodes);
        dataColumns.value = new DataColumns(parsedColumns, props.dataObject, {
            initialColumnsOptions: {
                hideMultiSelectColumn: props.hideMultiselectColumn,
                hideActionColumn: props.hideActionColumn,
                hideSystemColumn: props.hideSystemColumn,
            }
        });
    } else {
        if (props.dataObject) {
            dataColumns.value = DataColumns.fromDataObject(props.dataObject, {
                initialColumnsOptions: {
                    hideMultiSelectColumn: props.hideMultiselectColumn,
                    hideActionColumn: props.hideActionColumn,
                    hideSystemColumn: props.hideSystemColumn,
                }
            });
        }
    }
}


const dataGridControl = ref(new DataGridControl(props, {
    id: attrs.id,
    masterGrid: masterGrid?.value?.id,
    columns: dataColumns.value,
    proxyConstructor: reactive,
    disableTreeListener: !!props.groupByOptions,
    emit: emit
})); // 🚩 Refactor prop passing to a single option
dataGridControl.value.postCreateInit();
if (props.systemPropertyIsEditable) {

}
if (props.eagerGridControl) {
    props.eagerGridControl(dataGridControl);
}


provide(InjectionKeys.dataGridControlKey, dataGridControl);

dataGridControl.value.dataColumns.setupWatchers(watch, dataGridControl.value.watchColumnChanges.bind(dataGridControl.value));
const showSummaryRow = computed(() => !dataColumns.value.columns.every(col => !col.summaryAggregate));

//--- DOM REFERENCES ---
const containerRef = ref<HTMLElement | null>(null);
const viewportRef = ref<HTMLElement | null>(null);
const gridBody = ref<HTMLElement | null>(null);
const cellEditorRef = ref(null);
const contextMenuRef = ref(null);
const widthScrollerRef = ref(null);

//--- COMPUTED ---


let arrayDataRef = null;
if (!props.dataObject) {
    arrayDataRef = useArrayData(toRef(props, 'data'), dataGridControl);
}

const _data = computed(() => {
    if (props.dataObject) {
        return props.dataObject.data;
    } else if (props.data) {
        // @ts-ignore
        return arrayDataRef.value;
    } else {
        return [];
    }
});

const dataLength = computed(() => {
    if (props.dataObject && props.dataObject.hasNodeData && props.dataObject.nodeData.enabled) {
        return props.dataObject.data.length;
    } else if (props.dynamicLoading && props.dataObject) {
        //return props.dataObject.data.length;
        // const rowCount = props.dataObject.filterObject.appliedFilterString ? props.dataObject.filteredRowCount : props.dataObject.rowCount;
        const rowCount = props.dataObject.rowCount;
        return rowCount === -1 || rowCount == null ? props.dataObject.data.length : rowCount;
    } else if (props.dataObject) {
        return props.dataObject.data.length;
    } else if (dataGridControl.value.utils?.filteredRowCount != null) {
        return dataGridControl.value.utils.filteredRowCount;
    } else {
        return props.data?.length;
    }
});

const viewPortWidth = computed(() => {
    setViewPortWidth();
    return scrollerWidth.value;
}); // 🚩 Check usage

/** Indicates that the grid has non empty cardheader slot and should apply height fixes on it  */
const containerHasHeader = computed(() => {
    return !props.noContainerHeader && (slots.cardheader !== undefined && slots.cardheader().length !== 0) || !!props.headerTitle;
});

const computedRowClass = computed(() => {
    switch (typeof props.rowClass) {
        case 'function':
            return props.rowClass;
        case 'object':
        case 'string':
            return () => props.rowClass;
        default:
            return () => '';
    }
}); // 🚩 Check usage
const computedRowStyle = computed(() => {
    switch (typeof props.rowStyle) {
        case 'function':
            return props.rowStyle;
        case 'object':
        case 'string':
            return () => props.rowStyle;
        default:
            return () => '';
    }
}); // 🚩 Check usage

const editMode = computed(() => {
    return dataGridControl.value.hasNavigation ? dataGridControl.value.navigation?.editMode : false;
}); // 🚩 Check usage

const activeCell = computed(() => {
    return dataGridControl.value.hasNavigation ? dataGridControl.value.navigation?.activeCellString : undefined;
});

const activeEditCell = computed(() => {
    return dataGridControl.value.navigation?.editMode ? activeCell.value : null;
});

//--- COMPOSABLES ---
if (props.dataObject && props.dynamicLoading) {
    if (props.dataObject.recordSource.maxRecords === -1) {
        props.dataObject.recordSource.maxRecords = 50;
        if (props.dataObject.state.isLoading) {
            console.warn(`${props.dataObject.id} with maxRecords -1 has started loading before the grid is mounted. Dynamic loading will fail since all data is loaded. Please load this DataObject in onMounted hook or set the maxRecords.`);
        } else if (props.dataObject.state.isLoaded) {
            console.warn(`${props.dataObject.id} with maxRecords -1 has been loaded before the grid is mounted. Dynamic loading will fail since all data is loaded. Please load this DataObject in onMounted hook or set the maxRecords.`);
        }
    }
    props.dataObject.enableDynamicLoading();
    if (props.dataObject?.hasNodeData && props.dataObject.nodeData.enabled) {
        props.dataObject.dynamicLoading.enabled = false;
    }
}

const { setRowHover } = useDataGridHover({
    viewportRef: viewportRef,
    onHover: (index) => { dataGridControl.value.emit('RowHover', index) },
    onHoverLeave: (index) => { dataGridControl.value.emit('RowHoverLeave', index) },
});
dataGridControl.value.utils.setRowHover = setRowHover;

// if (!props.disableNavigation) {
// const { navigationControl: navControl } = useDataGridNavigation({
// containerRef: viewportRef,
// gridControl: dataGridControl,
// rowSelector: '.o365-body-row',
// cellSelector: '.o365-body-cell',
// widthScrollerRef: widthScrollerRef,
// cellEditorRef: cellEditorRef,
// contextMenuRef: contextMenuRef,
// enableDrag: true
// });
// dataGridControl.value.gridNavigationControl = navControl;
// }

useCancelToken(dataGridControl.value.layoutManager?.initialize())

useAllowEditWatcher({
    gridControl: dataGridControl
});

const virtualScrollWatcherTarget = computed(() => {
    /*
        switch (dataGridControl.value.currentWatchTargetType) {
            case 'arrayData':
                return arrayDataRef.value;
            case 'treeify':
                return props.dataObject.treeify?.updated;
            case 'groupBy':
                return props.dataObject.groupBy?.updated;
            case 'nodeData':
                return props.dataObject.nodeData?.updated;
            case 'dataObjectStorage':
                return props.dataObject.storage.updated;
        }
        
        */
    if (dataGridControl.value.dataObject == null) {
        return arrayDataRef.value;
    } else if (props.treeColumnDefinition) {
        return props.dataObject.treeify?.updated;
    } else if (props.groupBy && dataGridControl.value.dataObject.groupBy?.enabled) {
        return props.dataObject.groupBy?.updated;
    } else if (props.nodeData || (props.dataObject?.hasNodeData && props.dataObject.nodeData.enabled)) {
        return props.dataObject.nodeData?.updated;
    } else {
        return props.dataObject.storage.updated;
    }
});

function setupVirtualScroll() {
    if (props.isFullWidthRow != null || true) {
        const useAutoHeight = dataGridControl.value.dataColumns.columns.some(col => col.autoHeight);
        const options: Parameters<typeof useVirtualScroll2>[0] = {
            data: arrayDataRef ?? _data,
            itemSize: props.rowHeight,
            container: viewportRef,
            getItemSize: useAutoHeight
                ? props.getRowHeight ?? (() => 34)
                : props.getRowHeight,
            buffer: 6,
        };
        if (props.dynamicLoading && props.dataObject) {
            if (props.dataObject.recordSource.maxRecords < 50) {
                props.dataObject.recordSource.maxRecords = 50;
            }
            options.dataLength = computed(() => {
                const rowCount = props.dataObject?.rowCount;
                return rowCount === -1 || rowCount == null ? _data.value.length : rowCount;
            });
            options.onEndReached = () => {
                if (props.dataObject && (props.dataObject.rowCount == null || props.dataObject.rowCount == -1)) {
                    props.dataObject.dynamicLoading.loadNextPage();
                }
            }
        }
        const vs = useVirtualScroll2(options);
        if (props.dynamicLoading && props.dataObject) {
            useDataObjectEventListener(props.dataObject, 'DynamicDataLoaded', (pClear) => {
                if (pClear) {
                    if (viewportRef.value) {
                        viewportRef.value.scrollTop = 0;
                    }
                }
            });
            watch(() => vs.currentPosition.value, (pStart) => {
                if (props.dataObject?.dynamicLoading?.enabled) {
                    props.dataObject.dynamicLoading.currentStart = pStart;
                }
            });
        }
        vs.totalHeight = vs.totalSize;
        vs.getRowHeightByIndex = vs.getSizeByIndex;
        vs.updateWatcher = () => { };
        vs.updateData = vs.updateScrollItems;
        return vs;
    } else {
        return useVirtualScroll({
            dataRef: arrayDataRef ?? _data,
            itemSize: props.rowHeight,
            elementRef: viewportRef,
            itemsToRender: props.itemsToRender,
            dataObject: props.dynamicLoading ? props.dataObject : undefined,
            watchTarget: () => virtualScrollWatcherTarget.value
        });
    }
}


const { scrollData, handleScroll, updateData: updateVirtualScrollData,
    totalHeight: vs_totalHeight,
    getPosByIndex: vs_getPosByIndex,
    getRowHeightByIndex: vs_getRowHeightByIndex,
    updateRowHeight: vs_updateRowHeight,
    updateWatcher: vs_updateWatcher
} = setupVirtualScroll();
dataGridControl.value.virtualScrollApi = {
    getRowHeightByIndex: vs_getRowHeightByIndex,
    getPosByIndex: vs_getPosByIndex,
    totalHeight: vs_totalHeight,
    updateRowHeight: vs_updateRowHeight,
    updateWatcher: vs_updateWatcher
};
dataGridControl.value.updateVirtualScrollData = updateVirtualScrollData;
dataGridControl.value.getItemByScrollIndex = (pIndex) => scrollData.value.find(x => x.index === pIndex);


const scrollTop = defineModel<number | undefined>('scrollTop');
let scrollUpdateIsInteranl = false;
/** Cached scroll value for grids in KeepAlive scope */
let lastKnownScroll: number | undefined = 0;
/** Indicates the the scroll model value update is from internal event */
const handleGridScroll = (e) => {
    // scrollUpdateIsInteranl = true;
    handleScroll(e);
    // TODO: Replace to use the model one
    lastKnownScroll = viewportRef.value?.scrollTop;
    scrollTop.value = viewportRef.value?.scrollTop;
};

if (slots.subregion || props.syncScrollTop) {
    watch(() => scrollTop.value, (newScroll) => {
        if (!scrollUpdateIsInteranl && viewportRef.value) {
            viewportRef.value.scrollTop = newScroll ?? 0;
            scrollTop.value = viewportRef.value.scrollTop;
        } else {
        }
        scrollUpdateIsInteranl = false;
    });
}

if (props.autoScrollOnIndexChange && props.dataObject) {
    useDataObjectEventListener(props.dataObject, 'CurrentIndexChanged', (pNewIndex) => {
        if (pNewIndex != null && pNewIndex >= 0) {
            dataGridControl.value.navigation.scrollToRow(pNewIndex);
        }
    });
}

// Dynamic pinning for Action Column
if (!props.hideActionColumn) {
    if (props.disableRightPinnedOffset) {
        const actionColumn = dataGridControl.value.dataColumns.getColumn('o365_Action');
        dataGridControl.value.dataColumns.setColumnOrder(actionColumn, dataGridControl.value.dataColumns.columns.length, false)
        actionColumn.pinned = 'right';
        dataGridControl.value.dataColumns.updateColumnArrays();
    } else {
        watch(() => showWidthScrollbar.value, (scrollbarShown) => {
            const actionColumn = dataGridControl.value.dataColumns.getColumn('o365_Action');

            if (scrollbarShown) {
                dataGridControl.value.dataColumns.setColumnOrder(actionColumn, dataGridControl.value.dataColumns.columns.length, false)
                actionColumn.pinned = 'right';
            } else {
                let lastUnpinnedIndex = dataGridControl.value.dataColumns.columns.reduce((res, col) => {
                    if (!col.pinned && col.order > res) { res = col.order }
                    return res;
                }, -1);
                if (lastUnpinnedIndex === -1) { lastUnpinnedIndex - dataGridControl.value.dataColumns.leftColumns.length; }
                dataGridControl.value.dataColumns.setColumnOrder(actionColumn, lastUnpinnedIndex + 1, false)
                actionColumn.pinned = null;
            }
            dataGridControl.value.dataColumns.updateColumnArrays();
        });
    }
}

function updateGridBody(pEl?: HTMLElement) {
    gridBody.value = pEl;
    dataGridControl.value.scopedContainer = pEl;
}

const girdUID = crypto.randomUUID();
dataGridControl.value.uid = girdUID;

function gridQuery(query, isBody = false) {
    if (isBody) {
        return `.grid-${girdUID}${query}`;
    } else {
        return `.grid-${girdUID} ${query}`;
    }
}

const hasFlexColumns = computed(() => {
    return dataGridControl.value.dataColumns.columns.some(x => x.flexWidth);
});

//--- FUNCTIONS ---
function setViewPortWidth() {
    if (dataGridControl.value.notVisible) { return; }
    if (containerRef && containerRef.value) {
        if (!containerRef.value.querySelector(gridQuery('.o365-grid-body', true))) { return; }
        // scrollerWidth.value = containerRef.value.querySelector(gridQuery('.o365-grid-body', true)).clientWidth - dataGridControl.value.dataColumns.leftPinnedWidth - dataGridControl.value.dataColumns.rightPinnedWidth - 14//To do remove this if scroller n visible;
        scrollerWidth.value = gridBody.value?.clientWidth - dataGridControl.value.dataColumns.leftPinnedWidth - dataGridControl.value.dataColumns.rightPinnedWidth - 14//To do remove this if scroller n visible;
        if (hasFlexColumns.value) {
            let prevWidth = dataGridControl.value.dataColumns.unusedWidth;
            let unusedWidth = scrollerWidth.value - dataGridControl.value.dataColumns.centerWidth;
            unusedWidth = unusedWidth < 0 ? 0 : unusedWidth;
            if (prevWidth !== unusedWidth) {
                dataGridControl.value.dataColumns.unusedWidth = unusedWidth;
                dataGridControl.value.dataColumns.updateWidths();
            }
        }

        const viewport = containerRef.value.querySelector(gridQuery('.o365-body-center-cols-container'));
        const widthScrollbarIsShown = showWidthScrollbar.value;
        showWidthScrollbar.value = scrollerWidth.value < viewport.scrollWidth;
        if (widthScrollbarIsShown !== showWidthScrollbar.value) {
            containerRef.value?.querySelectorAll(gridQuery('.o365-grid-container')).forEach(container => {
                if (!showWidthScrollbar.value && container) {
                    container.style.transform = 'translate(0px)';
                }
            });
        }
        window.requestAnimationFrame(() => {
            dataGridControl.value.updateWidthScrollState(containerRef.value?.querySelector(gridQuery('.o365-body-horizontal-scroll-viewport')));
        });
    }
}

let updateViewpoertDebounce = null;
function updateViewpoertWidth() {
    if (updateViewpoertDebounce) { window.clearTimeout(updateViewpoertDebounce); }
    updateViewpoertDebounce = window.setTimeout(() => {
        nextTick().then(() => {
            setViewPortWidth();
        });
        updateViewpoertDebounce = null;
    }, 10);
}

dataGridControl.value.updateViewport = updateViewpoertWidth;
dataGridControl.value.setViewPortWidth = setViewPortWidth;

/** Get the selection classes based on col and row indexes  */
function getSelectionClass(colIndex, rowIndex) {
    if (!dataGridControl.value.gridSelectionInterface?.selectionClassMap) { return; }

    const classMap = dataGridControl.value.gridSelectionInterface?.selectionClassMap['G']?.[rowIndex]?.[colIndex];
    let className;
    if (classMap) {
        className = classMap.join(' ');
    }

    if (activeCell.value && activeCell.value === `G_${colIndex}_${rowIndex}`) {
        className = (className ? className + ' ' : '') + 'o365-focus-cell';
    }

    return className;
}

/** Set the vertical scroll position of the viewport */
function setScrollPosition(pos) {
    if (viewportRef.value) {
        viewportRef.value.scrollTop = pos;
    }
}


/** Get the vertical scroll position of the viewport */
function getScrollPosition() {
    return viewportRef.value.scrollTop;
}

dataGridControl.value.getVerticalScrollViewport = () => viewportRef.value;

watch(() => dataGridControl.value.state.isLoading, (newValue, prevValue) => {
    if (prevValue && !newValue) {
        dataGridControl.value.clearSelection();
    }
});

//----------------------------------------------------------------------------------------------
// BATCH RECORDS 
//----------------------------------------------------------------------------------------------

dataGridControl.value.disableBatchRecords = props.disableBatchRecords

/**
 * Used only by header
 * TODO: Move into header
 */
const showNewRecordButton = computed(() => {
    return (dataGridControl.value.state.allowInsert && props.nodeData == null && !props.hideNewRecords && !props.dataObject?.hasNodeData)
        && (!dataGridControl.value.dataObject?.batchDataEnabled || dataGridControl.value.newRecordsPosition == 'bottom');
});

async function createNewRecord(pSetPosition = false) {
    const position = pSetPosition ? 'above-filters' : undefined;
    dataGridControl.value.enableBatchRecords(position);
}

const hasNewRecords = computed(() => {

    return dataGridControl.value.dataObject?.batchDataEnabled && dataGridControl.value.dataObject?.batchData.data.length > 0;;
    //return dataGridControl.value.dataObject.batchData?.length > 0;
});

function autoEnableBatchRecords() {
    if (dataGridControl.value.hasNodeData) { return; }
    if (!props.hideNewRecords && dataGridControl.value.showNewRecordsPanel && props.dataObject?.allowInsert && !hasNewRecords.value && !props.treeColumnDefinition && !props.nodeData) {
        createNewRecord();
        dataGridControl.value.autoNewRecordsPanel = true;
    }
}

//----------------------------------------------------------------------------------------------
if (props.nodeData) {
    import('o365-nodedata').then(() => {
        dataGridControl.value.nodeData.initialize(props.nodeData);
    });
}


//----------------------------------------------------------------------------------------------
// LIFECYCLE HOOKS
//----------------------------------------------------------------------------------------------

if (props.rowDrag || props.rowDragOptions) {
    dataGridControl.value.hasRowDrag = true;
}

let batchLengthChangeCt = null;
onMounted(() => {
    dataGridControl.value.initializeContainer(containerRef.value, undefined, gridBody.value);
    if (dataGridControl.value.hasNavigation) {
        dataGridControl.value.navigation.cellEditorRef = cellEditorRef;
        dataGridControl.value.navigation.contextMenuRef = contextMenuRef;
    }
    window.setTimeout(() => {
        setViewPortWidth();
    }, 60);

    window.addEventListener('resize', () => {
        if (dataGridControl.value.menuTabs?.updateSidepanelWidth) {
            dataGridControl.value.menuTabs.updateSidepanelWidth();
        }
        setViewPortWidth();
    });

    window['setViewPortWidth'] = setViewPortWidth

    //setScrollData();
    emit('mounted');

    const modal = containerRef.value.closest('.modal');
    if (modal) {
        modal.addEventListener('shown.bs.modal', () => {
            setViewPortWidth();
        });
    }
    const tabPane = containerRef.value.closest('.tab-pane');
    if (tabPane && tabPane.id) {
        const tabEl = document.querySelector(`[data-bs-target="#${tabPane.id}"]`)
        tabEl?.addEventListener('shown.bs.tab', () => {
            setViewPortWidth();
        })
    }

    viewportRef.value?.addEventListener('click', (e) => {
        const target = e.target;
        const closest = target.closest('.o365-body-row');
        const rowIndex = closest?.getAttribute('data-o365-rowindex');
        const row = dataGridControl.value.dataObject?.data[rowIndex] ?? _data.value[rowIndex];
        if (row) {
            if (props.rowclickhandler) {
                props.rowclickhandler(row, e);
            } else {
                if (row.index != null) {
                    dataGridControl.value.setCurrentIndex(row.index);
                } else if (rowIndex && !props.dataObject) {
                    dataGridControl.value.setCurrentIndex(+rowIndex);
                }

                // dataGridControl.value.setCurrentIndex(row.index ?? parseInt(rowIndex));
            }
        }
    });

    if (props.rowDrag || props.rowDragOptions) {
        import('./extensions.RowDrag.ts').then(() => {
            dataGridControl.value.rowDrag.enable(props.rowDragOptions)
        });
    }

    if (!props.createNewOverrideFn) {
        autoEnableBatchRecords();
    }
});

onActivated(() => {
    if (viewportRef.value == null) { return; }
    const cachingTab = viewportRef.value?.closest('.o365-caching-tab');
    if (cachingTab) {
        const tabsEl = document.body.querySelector(`.o365-tabs-${cachingTab.dataset.tabsId}`)
        if (tabsEl) {
            const tabShownHandler = (e) => {
                if (viewportRef.value == null) { return; }
                viewportRef.value.scrollTop = lastKnownScroll;
                dataGridControl.value.resetWidthScroll();
                tabsEl.removeEventListener('shown.bs.tab', tabShownHandler);
            };
            tabsEl.addEventListener('shown.bs.tab', tabShownHandler);
        }
    } else if (viewportRef.value) {
        viewportRef.value.scrollTop = lastKnownScroll;
    }
    dataGridControl.value.observeMainList();
    dataGridControl.value.observeIntersections();
});

onDeactivated(() => {
    dataGridControl.value.unobserveMainList();
    dataGridControl.value.unobserveIntersections();
});


onBeforeUnmount(() => {
    dataGridControl.value.isBeingUnmounted = true;

    dataGridControl.value.destroy();

    // 🚩 Move this to grid destroy() function
    if (batchLengthChangeCt) {
        batchLengthChangeCt();
    }
});

const [capturedError, ErrorRenderer] = useErrorCapture({
    consoleMessagee: `Error encountered when trying to render grid content: ${dataGridControl.value?.id}`,
    errorRenderFunctionOptions: {
        type: 'card',
        uiMessage: 'An unhandled error has occurred when rendering the contents of this grid'
    }
});

// Force grid menu to be initially collapsed and add min-width to the grid on touch devices
let isTouch = false;
try {
    isTouch = matchMedia('screen and (pointer: coarse)')?.matches ?? false;
} catch (ex) {
    console.warn(ex);
}

if (props.loadDataObject) {
    if (!props.dataObject.state.isLoading && !props.dataObject.state.isLoaded) {
        props.dataObject.load();
    }
}

let prevEditorKey: string | null = null;
function updateEditorRef(pCmp: object | null, pKey: string) {
    if (pCmp == null) {
        if (prevEditorKey === pKey) {
            cellEditorRef.value = null;
        }
    } else {
        cellEditorRef.value = pCmp;
    }
    prevEditorKey = pKey;
}
dataGridControl.value.updateEditorRef = updateEditorRef;

const virtualColumns = props.enableVirtualColumns
    ? useVirtualColumns(dataGridControl)
    : {};


const exposedRef = ref({ dataColumns, setViewPortWidth, dataGridControl, setScrollPosition, getScrollPosition });
provide(InjectionKeys.dataGridRefKey, exposedRef);

provide(InjectionKeys.dataGridModelsKey, {
    scrollTop
});

defineExpose({ dataColumns, setViewPortWidth, dataGridControl, setScrollPosition, getScrollPosition });

</script>

<template>
    <div class="o365-data-grid o365-root row-container bg-body" tabindex="-1" :class="{
        'o365-data-grid-no-cell-borders': !renderCellBorders,
        'with-card-header': containerHasHeader,
        'o365-no-active-rows': !activeRows,
        'o365-data-grid-lookup': isLookup,
        'no-right-pinned-offset': disableRightPinnedOffset
    }" ref="containerRef" :id="dataGridControl.id">

        <ErrorRenderer v-if="capturedError" />
        <template v-else>

            <div class="o365-column-definitions d-none">
                <slot></slot>
            </div>

            <BodyWrapper :disabled="hideGridMenu">

                <GridSidePanel v-if="dataGridControl.leftSidepanel && !hideGridMenu" :gridRef="dataGridControl"
                    :containerHasHeader="containerHasHeader" ref="gridMenu" :disableGridMenuPersist="disableGridMenuPersist"
                    :initialVisible="!collapseGridMenu && !isTouch" :tabs="menuTabs" :iframeSrc="detailIframe"
                    :initialWidth="isTouch ? '30%' : initialMenuWidth" leftSidepanel :collapseMenuAt="collapseMenuAt"
                    :groupByFolders="groupByFolders" :detailTabTitle="detailTabTitle"
                    :maxWidth="isTouch ? '100%' : gridMenuMaxWidth" :minWidth="isTouch ? '20%' : gridMenuMinWidth"
                    :messageChannelId="detailMessageChannelId" :messageChannelFunctions="detailMessageChannelFunctions"
                    :no-header="noDetailHeader" :no-footer="noDetailFooter">
                    <template #filterBottom>
                        <!-- @slot 
                                                    @ignore -->
                        <slot name="setupFilterBottom"></slot>
                    </template>
                    <template v-if="$slots.detailTab" #detailTab>
                        <slot name="detailTab"></slot>
                    </template>
                    <template v-for="tab in menuTabs" v-slot:[`tab(${tab.id})`]>
                        <!-- @slot 
                                                    @ignore -->
                        <slot :name="`menu-tab(${tab.id})`"></slot>
                    </template>
                    <template v-if="$slots.detailActions" #detailActions>
                        <slot name="detailActions"></slot>
                    </template>
                </GridSidePanel>

                <BodyWrapper :disabled="hideGridMenu" :class="isTouch ? `mobile-grid-container` : fixedGridSize ? undefined : 'o365-datagrid-viewport-panel'">

                    <GridSidePanelTabs v-if="dataGridControl.leftSidepanel && !hideGridMenu" left
                        :detailTabTitle="detailTabTitle" />

                    <div class="o365-grid-body" :ref="updateGridBody"
                        :class="[`grid-${girdUID}`, { 'center-viewport-overflown': showWidthScrollbar, 'grid-scroll-at-start': dataGridControl.widthScrollState === 'start', 'grid-scroll-at-end': dataGridControl.widthScrollState === 'end' }]">

                        <ErrorBoundry v-if="containerHasHeader" type="span"
                            uiTitleMessage="Am unhandled error has occured when trying to render this card header"
                            uiMessage="Grid Header Render Error"
                            :consoleMessage="`Error encountered when trying to render grid cardheader: ${dataGridControl?.id}`">
                            <div class="o365-card-header hstack pt-2">

                                <h4 v-if="headerTitle" class="mb-0 p-2 me-2">{{ $t(headerTitle) }}</h4>
                                <slot name="cardheader" :enable="editMode"></slot>

                            </div>
                            <!--
                                            <slot v-else name="cardheader" :enable="editMode"></slot>
                                            -->
                        </ErrorBoundry>


                        <BodyWrapper :disabled="!$slots.subregion"
                            :style="$slots.subregion && !noFooter ? 'height: calc(100% - 90px)!important' : undefined">
                            <BodyWrapper :disabled="!$slots.subregion" class="flex-column">


                                <ODataGridHeader v-if="!noHeader" v-model="dataColumns.columns"
                                    :data-grid-control="dataGridControl" :filter-row="!disableFilterRow"
                                    :containerRef="containerRef" :setViewPortWidth="setViewPortWidth"
                                    :showNewRecordButton="showNewRecordButton" :createNewRecord="createNewRecord"
                                    :hasNewRecords="hasNewRecords" :gridMenu="gridMenu" :hideGridMenu="hideGridMenu"
                                    :use-left-column-sizers="useLeftColumnSizers">

                                    <template v-if="dataGridControl.hasNodeData && dataGridControl.nodeData.groupBy" #top>
                                        <DataGridGroupByContainer compactMode hideHeader />
                                    </template>

                                </ODataGridHeader>

                                <ODataGridDataList :data="scrollData" :dataGridControl="dataGridControl"
                                    :viewportRefFunction="el => { viewportRef = el }" :handle-scroll="handleGridScroll"
                                    :dataLength="dataLength" class="o365-main-list"
                                    :dataHeight="getRowHeight ? vs_totalHeight : null">
                                    <template #overlay>
                                        <Overlay v-if="dataGridControl.state.isLoading"
                                            :style="{ top: viewportRef?.scrollTop + 'px' }" />
                                        <slot v-else-if="noRowsFound && scrollData.length === 0" name="noRowsFound">
                                            <div :style="{ 'min-height': rowHeight + 'px' }"
                                                class="border-bottom w-100 ps-2 position-absolute d-flex align-items-center">
                                                {{ typeof noRowsFound === 'string' ? noRowsFound : $t('No rows found...') }}
                                            </div>
                                        </slot>
                                        <slot name="overlay"></slot>
                                    </template>
                                    <template #left="{ row, rowIndex }">
                                        <DataGridBodyRow :row="row" pinned="left" :rowIndex="rowIndex"
                                            :rowClass="row.item ? computedRowClass(row.item, 'left') : undefined"
                                            :rowStyle="row.item ? computedRowStyle(row.item, 'left') : undefined"
                                            :getSelectionClass="getSelectionClass" :activeEditLocation="activeEditCell"
                                            :activeRows="activeRows" :updateEditorRef="dataGridControl.updateEditorRef"
                                            container="G" :viewportRef="viewportRef" />
                                    </template>
                                    <template #center="{ row, rowIndex }">
                                        <DataGridBodyRow :row="row" :rowIndex="rowIndex"
                                            :rowClass="row.item ? computedRowClass(row.item) : undefined"
                                            :rowStyle="row.item ? computedRowStyle(row.item) : undefined"
                                            :getSelectionClass="getSelectionClass" :activeEditLocation="activeEditCell"
                                            :activeRows="activeRows" :updateEditorRef="dataGridControl.updateEditorRef"
                                            container="G" :viewportRef="viewportRef" />
                                    </template>

                                    <template #right="{ row, rowIndex }">
                                        <DataGridBodyRow :row="row" pinned="right" :rowIndex="rowIndex"
                                            :rowClass="row.item ? computedRowClass(row.item, 'right') : undefined"
                                            :rowStyle="row.item ? computedRowStyle(row.item, 'right') : undefined"
                                            :getSelectionClass="getSelectionClass" :activeEditLocation="activeEditCell"
                                            :activeRows="activeRows" :updateEditorRef="dataGridControl.updateEditorRef"
                                            container="G" :viewportRef="viewportRef" />
                                    </template>

                                    <template #fullWidth="{ row, rowIndex }">
                                        <div class="o365-body-row" :data-o365-rowindex="rowIndex"
                                            :style="[{ height: row.rowHeight + 'px', 'transform': 'translateY(' + row.pos + 'px)' }, rowStyle]">
                                            <slot name="fullWidthRow" :row="row.item"></slot>
                                        </div>
                                    </template>

                                    <template #misc>
                                        <OGridContextMenu v-if="!disableNavigation" ref="contextMenuRef">
                                            <template #top="scope">
                                                <!-- @slot
                                                        @description Top part of the context menu -->
                                                <slot name="contextmenuTop" :column="scope.column" :row="scope.row"
                                                    :close="scope.close"></slot>
                                            </template>
                                            <template v-if="$slots.contextmenu" #default="scope">
                                                <slot name="contextmenu" :column="scope.column" :row="scope.row"
                                                    :close="scope.close"></slot>
                                            </template>
                                            <template #bottom="scope">
                                                <!-- @slot
                                                        @description Bottom part of the context menu -->
                                                <slot name="contextmenuBottom" :column="scope.column" :row="scope.row"
                                                    :close="scope.close"></slot>
                                            </template>
                                        </OGridContextMenu>
                                    </template>
                                </ODataGridDataList>


                                <ODataGridFooter v-if="showSummaryRow" :dataGridControl="dataGridControl" />

                                <div v-if="dataGridControl.state.isNextPageLoading"
                                    class="d-flex w-100 position-absolute bg-body"
                                    :style="{ bottom: showWidthScrollbar ? '54px' : '40px' }">
                                    <div class="progress w-100" style="height: 8px;">
                                        <div class="progress-bar indeterminate-progress" role="progressbar"></div>
                                    </div>
                                </div>

                                <div v-if="dataGridControl.newRecordsPosition == 'bottom' && !dataGridControl.isTable && !dataGridControl.isLookup && (dataGridControl.dataObject?.hasNewRecords || dataGridControl.newData)"
                                    class="border-top d-flex flex-column" style="max-height: 80%;">
                                    <ONewRecordsPanel :ref="cmp => { dataGridControl.newrecordsRef = cmp; }" isBottom
                                        :widthScrollContainerRef="widthScrollerRef" :dataGridControl="dataGridControl" />
                                </div>

                                <div v-show="showWidthScrollbar" ref="widthScrollerRef" class="o365-body-horizontal-scroll"
                                    style="height: 14px; max-height: 14px; min-height: 14px; width: 100%;">
                                    <div class="o365-body-horizontal-scroll-left-spacer"
                                        :style="{ 'min-width': dataColumns.leftPinnedWidth + 'px' }"></div>
                                    <div class="o365-body-horizontal-scroll-viewport"
                                        :style="{ 'width': viewPortWidth + 'px' }">
                                        <div class="o365-body-horizontal-scroll-container"
                                            style="height: 14px; max-height: 14px; min-height: 14px;"
                                            :style="[{ 'width': dataColumns.centerWidth + 'px', 'left': dataColumns.leftPinnedWidth + 'px' }]">
                                        </div>
                                    </div>
                                    <div class="o365-body-horizontal-scroll-left-spacer"
                                        :style="{ 'min-width': dataColumns.rightPinnedWidth + 'px' }"></div>
                                </div>
                            </BodyWrapper>

                            <OSizerPanel v-if="$slots.subregion" :id="`${dataGridControl.id}-subregion`" width="50%"
                                collapse useEvents @resizing="() => { dataGridControl.emit('SubRegionResizing') }">
                                <slot name="subregion"></slot>
                            </OSizerPanel>
                        </BodyWrapper>

                        <ODataGridStatusBar v-if="!noFooter" :data-object="dataObject" :dataGridControl="dataGridControl">
                            <template #default>
                                <slot name="statusbar"></slot>
                            </template>
                            <template v-if="$slots.infoItemsActions" #actionsEnd>
                                <slot name="infoItemsActions"></slot>
                            </template>
                        </ODataGridStatusBar>

                    </div>
 
                    <GridSidePanelTabs v-if="!hideGridMenu && !fixedGridSize && !dataGridControl.leftSidepanel"
                        :detailTabTitle="detailTabTitle" />
                    <GridSidePanelTabs v-else-if="fixedGridSize && dataGridControl?.menuTabs && dataGridControl?.menuTabs?.sizerState === 'collapsedRight'"
                        :detailTabTitle="detailTabTitle" />

                </BodyWrapper>

                <GridSidePanel v-if="!dataGridControl.leftSidepanel && !hideGridMenu" :gridRef="dataGridControl"
                    :containerHasHeader="containerHasHeader" ref="gridMenu" :initial-visible="!collapseGridMenu && !isTouch"
                    :tabs="menuTabs" :iframeSrc="detailIframe" :initialWidth="initialMenuWidth"
                    :groupByFolders="groupByFolders" :detailTabTitle="detailTabTitle" :maxWidth="gridMenuMaxWidth"
                    :minWidth="gridMenuMinWidth" :messageChannelId="detailMessageChannelId"
                    :messageChannelFunctions="detailMessageChannelFunctions" :no-header="noDetailHeader"
                    :no-footer="noDetailFooter">
                    <template #filterBottom>
                        <!-- @slot 
                                                    @ignore -->
                        <slot name="setupFilterBottom"></slot>
                    </template>
                    <template v-if="$slots.detailTab" #detailTab>
                        <slot name="detailTab"></slot>
                    </template>
                    <template v-for="tab in menuTabs" v-slot:[`tab(${tab.id})`]>
                        <!-- @slot 
                                                @ignore -->
                        <slot :name="`menu-tab(${tab.id})`"></slot>
                    </template>
                    <template v-if="$slots.detailActions" #detailActions>
                        <slot name="detailActions"></slot>
                    </template>
                </GridSidePanel>
            </BodyWrapper>

        </template>

    </div>
</template>

<style scoped>
.o365-grid-detail-link-container {
    height: 100%;
}

.o365-grid-detail-link-container .o365-grid-detail-link-copy {
    display: none;
}

.o365-grid-detail-link-container:hover .o365-grid-detail-link-copy {
    display: block;
}</style>
